import React, { useCallback, useEffect } from "react";

import { useModal } from "@common/components/Modal/Modal";
import { SubscriptionStatus } from "@common/models/billing";
import { MemberRole } from "@common/models/Member.model";
import { getTimedItem, setTimedItem } from "@common/util/localStorage";
import SubscriptionPaymentIssueElement from "@main/pages/SubscriptionPaymentIssueElement/SubscriptionPaymentIssueElement";
import useSocket from "common/hooks/useSocket";
import { Workspace } from "common/models";
import { useAppDeps } from "main/App.dependencies";
import { useAppsSocketConsumer } from "main/consumers/useAppsSocketConsumer";
import useChangeLogUnreadNotificationsApiConsumer from "main/consumers/useChangeLogNotificationsApiConsumer";
import useDesktopNotificationsSocketConsumer from "main/consumers/useDesktopNotificationsSocketConsumer";
import useNotificationsApiConsumer from "main/consumers/useNotificationsApiConsumer";
import useTeamMembersApiConsumer from "main/consumers/useTeamMembersApiConsumer";
import { setUpdatingWorkspaceAction, setWorkspacesAction } from "main/context/App/App.actions";
import { useAppContext, useAppDispatch } from "main/context/App/App.context";
import { useAppSelectionContext } from "main/context/App/AppSelectionContext";
import { Sidebar } from "main/layouts/components/Sidebar/Sidebar";
import { ChromeExtensionButton } from "main/pages/shared/components/ChromeExtensionButton/ChromeExtensionButton";
import { ChromeExtensionLoginDialog } from "main/pages/shared/components/ChromeExtensionLoginDialog/ChromeExtensionLoginDialog";
import { Outlet } from "react-router";

import * as Styled from "./WorkspaceRootLayout.styles";

export const WorkspaceRootLayout = () => {
  // const location = useLocation();

  /// Connect to socket for workspace
  const { wsTeamMembers } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const { socket } = useSocket(selectedWorkspace?.id);
  useDesktopNotificationsSocketConsumer(socket);
  useAppsSocketConsumer(socket);
  const { fetchCurrentTeamMembersAndDispatch } = useTeamMembersApiConsumer();
  const { fetchUnreadNotificationsAndDispatch } = useNotificationsApiConsumer();
  const { fetchUnreadChangelogNotificationsAndDispatch } = useChangeLogUnreadNotificationsApiConsumer();

  const {
    Modal: SubscriptionPaymentIssueModal,
    toggle: toggleSubscriptionPaymentIssueModal,
    modalProps: SubscriptionPaymentIssueModalProps,
    setActive: setActive,
  } = useModal({ size: "full" });

  useEffect(() => {
    if (
      selectedWorkspace &&
      selectedWorkspace.subscription_status === SubscriptionStatus.PAST_DUE &&
      selectedWorkspace.role != MemberRole.GUEST &&
      wsTeamMembers &&
      !getTimedItem(`shakebugs.billing.${selectedWorkspace.id}`)
    ) {
      setTimedItem(`shakebugs.billing.${selectedWorkspace?.id}`, selectedWorkspace?.id, 7 * 60 * 60 * 1000); // 7 days
      setActive(true);
    }
  }, [selectedWorkspace, wsTeamMembers]);

  useEffect(() => {
    if (!selectedWorkspace) return;
    const abortController = new AbortController();
    fetchUnreadNotificationsAndDispatch(selectedWorkspace.id, abortController.signal);
    fetchUnreadChangelogNotificationsAndDispatch(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [fetchUnreadNotificationsAndDispatch, fetchUnreadChangelogNotificationsAndDispatch, selectedWorkspace]);

  useEffect(() => {
    if (!selectedWorkspace) return;
    const abortController = new AbortController();

    fetchCurrentTeamMembersAndDispatch(selectedWorkspace.id);

    return () => {
      abortController.abort();
    };
  }, [fetchCurrentTeamMembersAndDispatch, selectedWorkspace]);

  // eslint-disable-next-line
  // Uncomment to enable sign up survey
  // if (location.state && (location.state as any).from == FROM_SIGNUP) return <MarketingChannelSurvey />;

  return (
    <Styled.Container>
      <Sidebar />
      <Styled.InnerContainer>
        <Outlet />
      </Styled.InnerContainer>
      <ChromeExtensionButton />
      <ChromeExtensionLoginDialog />
      <SubscriptionPaymentIssueElement
        isModal
        toggleModal={toggleSubscriptionPaymentIssueModal}
        Modal={SubscriptionPaymentIssueModal}
        internalProps={SubscriptionPaymentIssueModalProps}
      />
    </Styled.Container>
  );
};

/// Triggers fetchAllApps on workspace update.
let updateSelectedWSAbort = new AbortController();

export function useUpdateSelectedWorkspace() {
  const appDispatch = useAppDispatch();
  const { workspaces } = useAppContext();
  const { appsService } = useAppDeps();

  const updateSelectedWorkspace = useCallback(
    async (selectedWorkspace: Workspace) => {
      if (!selectedWorkspace) return;
      try {
        updateSelectedWSAbort.abort();
        updateSelectedWSAbort = new AbortController();

        appDispatch(setUpdatingWorkspaceAction(true));
        const { data: workspaceData } = await appsService.getTeamData(
          selectedWorkspace.id,
          updateSelectedWSAbort.signal,
        );
        if (updateSelectedWSAbort.signal && updateSelectedWSAbort.signal.aborted) return;

        const newWorkspaces = workspaces.map((w) => {
          if (w.id == workspaceData.id) {
            return {
              ...w,
              has_subscription: workspaceData.has_subscription,
              had_subscription: workspaceData.had_subscription,
              is_data_available: workspaceData.is_data_available,
            };
          }
          return w;
        });
        appDispatch(setWorkspacesAction(newWorkspaces));
      } catch (error) {
      } finally {
        if (updateSelectedWSAbort.signal && updateSelectedWSAbort.signal.aborted) return;
        appDispatch(setUpdatingWorkspaceAction(false));
      }
    },
    [appDispatch, workspaces, appsService],
  );

  return {
    updateSelectedWorkspace,
  };
}
